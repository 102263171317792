import React from 'react'
import DiscographyComponent from '../components/Discography/DiscographyComponent';

const Discography = () => {
    return (
        <>
            <DiscographyComponent />
        </>
    )
}

export default Discography
