import MainSection from '../components/MainSection/MainSection';

const Home = () => {
    return (
        <>
            <MainSection />
        </>
    )
}

export default Home;
