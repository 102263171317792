import React from 'react';
import TourComponent from '../components/TourComponent/TourComponent';

const Tour = () => {
    return (
        <>
            <TourComponent />
        </>
    )
}

export default Tour
