import React from 'react'
import MusicComponent from '../components/MusicComponent/MusicComponent';

const Music = () => {
    return (
        <>
            <MusicComponent />
        </>
    )
}

export default Music
