import React from 'react'
import VideosComponent from '../components/VideosComponent/VideosComponent';

const Videos = () => {
    return (
        <>
            <VideosComponent />
        </>
    )
}

export default Videos
