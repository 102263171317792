import React from 'react'
import ContactComponent from '../components/ContactComponent/ContactComponent';

const Contact = () => {
    return (
        <>
            <ContactComponent />
        </>
    )
}

export default Contact
