import React from 'react';
import MerchComponent from '../components/MerchComponent/MerchComponent';

const Merch = () => {
    return (
        <>
            <MerchComponent />
        </>
    )
}

export default Merch
